import React from "react";

const Contact = () => {
    return(
        <>
        <section className='pt-[120px] bg-[#FF8A00] px-8'>
            <h2 className='h2 font-bold'>
            JOIN THE WAITING LIST BELOW
            </h2>
            <form className='w-full py-[60px] lg:py-[120px] placeholder:text-[20px] space-y-8 lg:space-y-[60px]' name="contact" method="POST" data-netlify="true"   netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" className="hidden" />

                <p className="hidden">
    <label>
      Don’t fill this out if you’re human: <input name="bot-field" />
    </label>
  </p>


                <div className='lg:flex w-full lg:space-x-6 space-y-8 lg:space-y-0'>
                <div className='w-full'>
                    <label htmlFor='name' className='hidden'>Name</label>
                    <input name="name" type='text' id='name' className='w-full border-x-0 border-t-0 border-b border-white p-2 bg-transparent text-white placeholder-white' placeholder="NAME*"/>
                </div>
                <div className='w-full'>
                    <label htmlFor='email' className='hidden'>Email</label>
                    <input name="email" type='text' id='email' className='w-full border-x-0 border-t-0 border-b border-white p-2 bg-transparent text-white placeholder-white' placeholder="EMAIL*"/>
                </div>
                </div>
                <div className='lg:mt-[60px]'>
                    <label htmlFor='message' className='hidden'>Message</label>
                    <textarea name="message" id='message' className='w-full h-fit border-x-0 border-t-0 border-b border-white p-2 bg-transparent text-white placeholder-white lg:h-24' placeholder="MESSAGE*" rows={1}/>
                </div>
                <div>
                    <button className='text-white uppercase p-2 w-fit border-b border-white text-[20px] lg:text-[1.85vw] hover:opacity-75 transition-all'>
                        SEND
                    </button>
                </div>

            </form>
        </section>
        <footer className="bg-[#FF8A00] p-8 text-[10px] lg:text-[0.92vw] uppercase flex lg:justify-between flex-col lg:flex-row space-y-4 lg:space-y-0">
            <p>
            Ⓒ 2022 Orange Creative Co the Klaviyo Experts - All Rights Reserved.
            </p>
            <p>
                Sucky website by <a href="https://tensq.com.au/">Ten Squared</a>
            </p>
        </footer>
        </>
    )
}

export default Contact;